import "./App.css";
import React, { useEffect, useRef, useState } from "react";
import { RiRecycleLine } from "react-icons/ri";
import { useKeyPress } from "./hooks/useKeyPress";
import randomWords from "random-words";
import { useLocalStorage } from "./hooks/useLocalStorage";

//TODO: refactor some things into separate files to clean this huge file up

interface IScore {
  wpm?: number;
  accuracy?: number;
  inCorrect?: number;
}

function App() {
  const inputRef = useRef(null);
  const [timeLimit, setTimeLimit] = useState(15);
  const [topScores, setTopScores] = useLocalStorage<IScore[]>("topScores", []);
  const [wordStyle, setWordStyle] = useState("");
  const [boxStyle, setBoxStyle] = useState("words");
  const [seconds, setSeconds] = useState<number>(timeLimit);
  const [complete, setComplete] = useState<boolean>(false);
  const [active, setActive] = useState<boolean>(false);
  const [value, setValue] = useState<string>("");
  const [words, setWords] = useState<string[]>(
    randomWords({ maxLength: 6, exactly: 500 })
  );

  const [correct, setCorrect] = useState(0);
  const [inCorrect, setInCorrect] = useState(0);
  const onKeyPress = (event: any) => {
    handleResetTest();
  };

  useKeyPress(["/"], onKeyPress);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
    setActive(true);
    if (e.target.value === "") {
      setWordStyle("");
      return;
    }
    if (e.target.value.includes(" ") && !complete) {
      if (e.target.value === `${words[0]} `) {
        setBoxStyle("words ok");
        setCorrect((x) => x + 1);
      } else {
        setBoxStyle("words error");
        setInCorrect((x) => x + 1);
      }
      setWordStyle("");
      setValue("");
      let updated = [...words];
      if (updated.length > 0) {
        updated = updated.splice(1);
      }
      if (updated.length > 0) {
        setWords(updated);
      } else {
        setWords([" "]);
        setBoxStyle("words");
      }
      return;
    } else {
      words[0].startsWith(e.target.value)
        ? setWordStyle("correct")
        : setWordStyle("incorrect");
    }
  };

  const handleResetTest = () => {
    setBoxStyle("words");
    setWords(randomWords({ maxLength: 6, exactly: 500 }));
    setSeconds(timeLimit);
    setComplete(false);
    setActive(false);
    setValue("");
    setCorrect(0);
    setInCorrect(0);
    // @ts-ignore
    inputRef.current.focus();
  };

  const handleChangeTimeLimit = (time: number) => {
    if (!active) {
      setTimeLimit(time);
      setSeconds(time);
    }
  };

  const handleSetLocalScore = () => {
    const newScore: IScore = {
      wpm: Math.floor((correct * 60) / timeLimit),
      accuracy: Number(((correct * 100) / (correct + inCorrect)).toFixed(1)),
      inCorrect: inCorrect,
    };
    const scores = [...topScores, newScore].sort((a, b) => {
      return (b?.wpm || 0) - (a?.wpm || 0);
    });
    setTopScores([scores[0], scores[1], scores[2], scores[3], scores[4]]);
  };

  useEffect(() => {
    if (!topScores) {
      setTopScores([]);
    }
  }, [topScores, setTopScores]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (active && seconds > 0) {
        setSeconds((c) => c - 1);
      }
      if (seconds === 0) {
        setValue("");
        setComplete(true);
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [seconds, active, complete]);

  useEffect(() => {
    if (!topScores) {
      setTopScores([]);
    }

    if (complete) {
      handleSetLocalScore();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [complete]);

  return (
    <div className="main">
      <div className="mobile">
        <h1>Fury Keys</h1>
        <h3>Sorry this site is desktop only</h3>
      </div>
      <div className="content">
        <h1 className="title">Fury Keys</h1>

        {complete ? (
          <div></div>
        ) : (
          <div>
            <div className="time-selection">
              <div
                className="time-value"
                style={{ color: timeLimit === 15 ? "yellow" : "white" }}
                onClick={() => handleChangeTimeLimit(15)}
              >
                15
              </div>
              <div
                className="time-value"
                style={{ color: timeLimit === 30 ? "yellow" : "white" }}
                onClick={() => handleChangeTimeLimit(30)}
              >
                30
              </div>
              <div
                className="time-value"
                style={{ color: timeLimit === 60 ? "yellow" : "white" }}
                onClick={() => handleChangeTimeLimit(60)}
              >
                60
              </div>
            </div>
            <div className="blur">
              <div className={boxStyle}>
                {words.map((item, index) => {
                  return (
                    <p key={index} className={index === 0 ? wordStyle : ""}>
                      {item}
                    </p>
                  );
                })}
              </div>
            </div>
          </div>
        )}
        <div className="input-button-container">
          <div
            className="time"
            style={{
              backgroundColor:
                seconds > Math.floor(timeLimit / 2)
                  ? "rgb(9, 182, 101)"
                  : seconds > Math.floor(timeLimit / 4)
                  ? "rgb(241, 197, 2)"
                  : "rgb(228, 39, 39)",
            }}
          >
            {seconds}
          </div>
          <input
            ref={inputRef}
            type="text"
            onChange={handleInputChange}
            value={value}
            disabled={complete}
          />
          <button onClick={handleResetTest}>
            <span className="key-code">alt + /</span>
            <RiRecycleLine />
          </button>
        </div>
        {!active ? (
          <div className="call-to-action">
            ^^^ &nbsp; Type to Start &nbsp; ^^^
          </div>
        ) : (
          <div>&nbsp;</div>
        )}
        {complete ? (
          <div className="done">
            <h1>{Math.floor((correct * 60) / timeLimit)} WPM</h1>
            <h3>
              Accuracy: {((correct * 100) / (correct + inCorrect)).toFixed(1)}%
            </h3>
            <h3>
              Incorrect:{" "}
              <span style={{ color: "rgb(228, 39, 39)" }}> {inCorrect}</span>
            </h3>
          </div>
        ) : (
          <div></div>
        )}
        <div className="log">
          <table>
            <thead>
              <tr>
                <th style={{ width: "15%" }}>Top Scores</th>
                <th>WPM</th>
                <th style={{ width: "25%" }}>Accuracy</th>
                <th style={{ width: "25%" }}>Incorrect</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>1</td>
                <td>{topScores[0]?.wpm}</td>
                <td>{topScores[0]?.accuracy}%</td>
                <td>{topScores[0]?.inCorrect}</td>
              </tr>
              <tr>
                <td>2</td>
                <td>{topScores[1]?.wpm}</td>
                <td>{topScores[1]?.accuracy}%</td>
                <td>{topScores[1]?.inCorrect}</td>
              </tr>
              <tr>
                <td>3</td>
                <td>{topScores[2]?.wpm}</td>
                <td>{topScores[2]?.accuracy}%</td>
                <td>{topScores[2]?.inCorrect}</td>
              </tr>
              <tr>
                <td>4</td>
                <td>{topScores[3]?.wpm}</td>
                <td>{topScores[3]?.accuracy}%</td>
                <td>{topScores[3]?.inCorrect}</td>
              </tr>
              <tr>
                <td>5</td>
                <td>{topScores[4]?.wpm}</td>
                <td>{topScores[4]?.accuracy}%</td>
                <td>{topScores[4]?.inCorrect}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default App;
